:root {
  --wall: #D7D8D3;
  --floor: #423930;
  --text: #716F6C;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--wall);
}

h1 {
  font-size: 8vh;
  white-space: nowrap;
  font-family: "Montserrat";
  font-weight: 300;
  color: #716F6C;
}

h2 {
  font-size: 2.5vh;
  white-space: nowrap;
  font-family: "Montserrat";
  font-weight: 450;
  line-height: 1.5;
  color: #716F6C;
}

table {
  border-collapse: collapse;
  border: 2px solid #716F6C;
  font-size: 2vh;
  white-space: normal;
  font-family: "Montserrat";
  font-weight: 450;
  line-height: 1.5;
  color: #716F6C;
}

caption {
  caption-side: top;
  padding: 10px;
  font-weight: bold;
}

th,td {
  border: 1px solid #716F6C;
  padding: 8px 10px;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: #000000a3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard {
  display: grid;
  width: calc(100vw - 35px);
  gap: 10px;
  grid-template-columns: auto auto auto;
}

@media all and (min-width: 800px) and (max-width: 1200px) {
  .dashboard {
      grid-template-columns: auto auto;
  }   
}

@media all and (max-width: 800px) {
  .dashboard {
      grid-template-columns: auto;
  }
}